<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />
    <div>
       <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`padding-inline-end:35px;direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col cols="12" md="10" sm="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin">
                <form autocomplete="off">
                    <v-row>
                        
                    </v-row>
                </form>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="pt-9" :style="`direction:`+lang.ldir+`;text-align:`+lang.lalgin">
                <button id="multiply-button" :style="`direction:`+lang.dir" :class="`multiply-button-`+lang.lalgin" @click="addStore()" v-b-toggle.add_store>
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_store}}</div>
                </button>
            </v-col>
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.dir+`;margin-top:3px;text-align:`+lang.align">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    :search="search"
                    :items-per-page="15"
                    class="elevation-1"
                    hide-default-footer
                >
                <template v-slot:item="row">
                <tr>
                    <td class="text-center" style="width:70px">{{ row.item.classid }}</td>
                    <td class="text-center" nowrap>{{ row.item.name_ar }}</td>
                    <td class="text-center" nowrap>{{ row.item.name_en }}</td>
                    <td class="text-center" style="color:#fff !important;cursor:pointer;width:70px;background:red;border-bottom:2px solid #FFF !important;" @click="deleteStore(row.item.id,index)">
                        {{lang.delete}}
                    </td>
                    <td class="text-center" style="color:#fff !important;cursor:pointer;width:70px;background:blue;border-bottom:2px solid #FFF !important;" @click="editStore(row.item)" v-b-toggle.add_store>
                        {{lang.view}}
                    </td>
                </tr>
                </template>
                
                </v-data-table>
                
            </v-col>
        </v-row> 
    </div>
        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
        <v-progress-circular
            :width="3"
            color="green"
            indeterminate
        ></v-progress-circular>
        </div>
        <addStore ref="addStore" />
    </div>
</template>

<script>
import axios from 'axios';
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import addStore from '@/components/addStore.vue';
import ExportsPrint from '@/components/exports-print.vue';
export default{
    components:{
        breadCrumbs,TabsComp,
        HeaderPortrate,Footer, ExportsPrint,addStore
    },
    data() {
        return {
            items: [],
            cpage:0,
            id:0,
            active_tab:2,
            search: '',
            page: 1,
            pageCount:0,
            tablerows: []
        }
    },
    computed: {
       lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
       
        headers: function() {
            return [
                {
                    text: this.lang.account_number,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.store_name_ar,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.store_name_en,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.delete,
                    align: 'center',
                    sortable: false,
                    class:"backBlack",
                    value: 'none',
                    printRemove: 1
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    class:"backBlack",
                    value: 'none',
                    printRemove: 1
                }
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;         
        },
       
    },
    methods: {
        deleteStore(id,index){
            this.tablerows.splice(index,1);
            const post = new FormData();
            
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "deleteStore");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.getStores();
                }
            })
        },
        addStore(){
            this.$refs.addStore.id = 0;
            this.$refs.addStore.store_name_ar = "";
            this.$refs.addStore.store_name_en = "";
            this.$refs.addStore.classid = "";
            this.$refs.addStore.acount_name = "";
        },
        editStore(item){
            this.$refs.addStore.id = item.id;
            this.$refs.addStore.store_name_ar = item.name_ar;
            this.$refs.addStore.store_name_en = item.name_en;
            this.$refs.addStore.classid = item.classid;
            this.$refs.addStore.getAccountName();
        },
        getStores(){
            const post = new FormData();
            post.append("type" , "getStores");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',"0")
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data)[
                    this.tablerows = response.data.results.data
                ]
            })
        },
    },
    created() {
        this.getStores()
    },
    
}
</script>